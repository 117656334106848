.full-width-text {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0;
  width: 100% !important;
}
.container {
  padding: 0 10px !important;
  margin: 15px;
  width: 600px !important;
}

@import 'src/styles/modules/muiTypography';
@import 'src/styles/modules/variables';

.popover {
  // margin-top: 2px;
}
.avatarHeader {
  border: 1px solid #8f0a0c;
}
.userNullState {
  @include mui-text('body1');
  padding: 20px 0 !important;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  cursor: default !important;
}
.editProfileButton {
  // color: #fff !important;
  // background-color: #2196f3 !important;
  // padding: 6px 8px !important;
  // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.header {
  display: flex;

  &:focus {
    outline: none; // prevent outline around profile subheader
  }

  .user-avatar {
    padding-right: 15px;
  }

  .user-profile {
    display: flex;
    flex-direction: column;
    align-items: left;

    .full-name {
      @include mui-text('body2');
      color: $dark-black;
    }

    .username {
      @include mui-text('caption');
    }

    .editButton {
      padding: 5px 0 10px;

      div {
        // fix shadow
        line-height: 36px;
      }
    }
  }
}

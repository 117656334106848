// colors

$light-red50: #ffebee;

$green-primary: #4caf50;
$green-bright: #00c753;

$grey-0: #e5e5e5;
$grey-1: #fafafa;
$grey-2: #eee;
$grey-3: #e8e8e8;
$grey-4: #e0e0e0;
$grey-5: #727272;
$grey-6: #666;

$light-blue50: #e1f5fe;
$light-blue: #e3f2fd;

$blue-1: #2196f3;
$blue-2: darken(#2196f3, 15%);
$blue-3: #2096f3;

$indigo500: #3f51b5;
$blue400: #42a5f5;
$greenA700: #00c853;
$orange600: #fb8c00;
$red500: #f44336;
$purple500: #9c27b0;

$full-black: rgba(0, 0, 0, 1);
$dark-black: rgba(0, 0, 0, 0.87);
$light-black: rgba(0, 0, 0, 0.54);
$semi-black: rgba(0, 0, 0, 0.44);
$min-black: rgba(0, 0, 0, 0.38);
$faint-black: rgba(0, 0, 0, 0.12);
$barely-black: rgba(0, 0, 0, 0.05);

$fullWhite: rgba(255, 255, 255, 1);
$darkWhite: rgba(255, 255, 255, 0.87);
$lightWhite: rgba(255, 255, 255, 0.54);

// grid

$width: 96%;
$gutter: 4%;
$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

// file picker variables
$file-picker-margin: 20px;

@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500);
@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,500);
@import url(https://static.veritone.com/veritone-ui/veritone-icons-26/style.css);
@import url(https://static.veritone.com/engines/assets/v001/style.css);
.styles_container__1bjkx {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_full-width-text__2IIsk {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0;
  width: 100% !important; }

.styles_container__1kLJ6 {
  padding: 0 10px !important;
  margin: 15px;
  width: 600px !important; }

.styles_fileUploaderSubtext__1_9sN {
  text-align: center;
  text-transform: none;
  margin-bottom: 0 !important;
  font-size: 30px; }
  .styles_fileUploaderSubtext__1_9sN.styles_subtextBlue__1hA_n {
    margin-left: 5px;
    color: #2196f3; }

.styles_iconUpload__2FOvr {
  color: #2196f3;
  font-size: 40px !important; }

.styles_highlight__kCyMI {
  background-color: rgba(255, 145, 145, 0.25); }

.styles_processContainer__2-HIo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_processingText__1ZnIy {
  margin-left: 20px; }

.styles_resultContainer__1wu5V {
  text-align: left;
  display: block; }

.switcher_switcher__fOLNM {
  position: relative;
  margin: 0 10px;
  height: calc(100vh - 20px);
  overflow: hidden;
  flex: 1 1; }

.switcher_switcher__fOLNM > div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #23282a;
  background: linear-gradient(0deg, #23282a, #23282a, #462b30);
  border: 1px solid #282832;
  box-sizing: border-box;
  color: white; }

.switcher_spinner__34_9O {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.switcher_spinner__34_9O > div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #141414;
  border: 1px solid #1e1e1e;
  box-sizing: border-box;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #373737;
  background: linear-gradient(to right, #dc2c30 10%, rgba(55, 55, 55, 0) 42%);
  position: relative;
  -webkit-animation: switcher_load3__1EW-I 1.4s infinite linear;
  animation: switcher_load3__1EW-I 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.switcher_spinner__34_9O > div:before {
  width: 50%;
  height: 50%;
  background: #dc2c30;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.switcher_spinner__34_9O > div:after {
  background: #31474f;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes switcher_load3__1EW-I {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes switcher_load3__1EW-I {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes switcher_load3__1EW-I {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* NOT FOUND */
.switcher_notFound__v9zTs {
  background: #141414;
  border: 1px solid #1e1e1e;
  box-sizing: border-box;
  padding: 10px;
  color: red !important;
  font-size: 32px;
  text-shadow: 1px 1px 1px black;
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_avatarHeader__NZL4G {
  border: 1px solid #8f0a0c; }

.styles_userNullState__1Vk_x {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 20px 0 !important;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  cursor: default !important; }

.styles_header__3-zEQ {
  display: flex; }
  .styles_header__3-zEQ:focus {
    outline: none; }
  .styles_header__3-zEQ .styles_user-avatar__1_0tP {
    padding-right: 15px; }
  .styles_header__3-zEQ .styles_user-profile__1uBpr {
    display: flex;
    flex-direction: column;
    align-items: left; }
    .styles_header__3-zEQ .styles_user-profile__1uBpr .styles_full-name__3KPkh {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      color: rgba(0, 0, 0, 0.87); }
    .styles_header__3-zEQ .styles_user-profile__1uBpr .styles_username__33SSK {
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 16px !important; }
    .styles_header__3-zEQ .styles_user-profile__1uBpr .styles_editButton__2Ms7C {
      padding: 5px 0 10px; }
      .styles_header__3-zEQ .styles_user-profile__1uBpr .styles_editButton__2Ms7C div {
        line-height: 36px; }

@-webkit-keyframes styles_fade-in__LrBY4 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes styles_fade-in__LrBY4 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes styles_spin__1WB4H {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes styles_spin__1WB4H {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.styles_notification__1F4xy {
  display: 'flex';
  align-items: 'center'; }
  .styles_notification__1F4xy .styles_toolTipWrapper__19cDE {
    display: inline-block; }
  .styles_notification__1F4xy .styles_badge__1olVy {
    top: -3px;
    right: -3px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    background-color: #8f0a0c; }

.styles_newText__3Fbjy {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* text-align: center; */
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #acc3d7;
  padding: 5px 15px;
  background: white; }

.styles_notificationWindow__1Za7c {
  width: 516px;
  height: 100%;
  color: white;
  background-color: rgba(255, 145, 145, 0.25); }
  .styles_notificationWindow__1Za7c .styles_header__1A6pj {
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    padding: 0 16px;
    background-color: #ffffff;
    color: #03294a;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #e6e9ed; }
    .styles_notificationWindow__1Za7c .styles_header__1A6pj .styles_label__Er04e {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px; }
    .styles_notificationWindow__1Za7c .styles_header__1A6pj .styles_read__HDu0p {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 10px;
      cursor: pointer; }
    .styles_notificationWindow__1Za7c .styles_header__1A6pj .styles_chip__tYJG9 {
      height: 16px;
      font-size: 10px;
      line-height: 15px;
      padding: 1px 10px 0;
      border-radius: 8px;
      margin-top: 16px;
      margin-left: 20px;
      background-color: #8f0a0c;
      color: #ffffff; }
    .styles_notificationWindow__1Za7c .styles_header__1A6pj .styles_controls__20K-d {
      margin-left: auto; }
    .styles_notificationWindow__1Za7c .styles_header__1A6pj .styles_headerLeft__1n4Am {
      display: flex; }
  .styles_notificationWindow__1Za7c .styles_body__2ZC-6 {
    height: auto;
    max-height: 352px; }

.styles_notificationList__3cVW6 {
  overflow: auto;
  max-height: inherit;
  background-color: #ffffff; }
  .styles_notificationList__3cVW6 .styles_entry__3AXXY {
    width: 100%;
    padding: 10px 30px 0 20px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    background-color: rgba(255, 145, 145, 0.25);
    cursor: pointer; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY:hover {
      background-color: rgba(255, 145, 145, 0.25); }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY.styles_fadeIn__2hVG_ {
      -webkit-animation: styles_fade-in__LrBY4 0.5s;
              animation: styles_fade-in__LrBY4 0.5s; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY.styles_slideOut__B_HyX {
      opacity: 0;
      height: 0;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: -600px;
      transition: opacity 0.6s, margin-left 0.6s, height 0.4s 0.6s, padding 0.4s 0.6s, margin-top 0.4s 0.6s, margin-bottom 0.4s 0.6s; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY.styles_fadeOut__1QQUg {
      opacity: 0;
      height: 0;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      transition: opacity 0.6s, margin-left 0.6s, height 0.4s 0.6s, padding 0.4s 0.6s, margin-top 0.4s 0.6s, margin-bottom 0.4s 0.6s; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY.styles_noOutro__2LSlk {
      display: none; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY > div:not(:last-child) {
      margin-right: 16px; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_visualStatus__1cM7z {
      width: 30px;
      padding: 0 25px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_description__2xd8C {
      flex: 1 1;
      min-width: 0;
      color: #03294a; }
      .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_description__2xd8C .styles_title__B5Zsf {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */
        align-items: center;
        text-align: justify; }
      .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_description__2xd8C .styles_subtitle__m2INK {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        /* Gray 3 */
        color: #828282;
        padding: 10px 0; }
    .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_extra__2PUHa {
      width: 130px;
      font-size: 12px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.54);
      display: flex;
      flex-direction: row;
      justify-items: center; }
      .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_extra__2PUHa .styles_description__2xd8C {
        flex: 1 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_extra__2PUHa .styles_actions__2oPpG .styles_iconButton__20gO7 {
        width: 32px;
        height: 32px; }
        .styles_notificationList__3cVW6 .styles_entry__3AXXY .styles_extra__2PUHa .styles_actions__2oPpG .styles_iconButton__20gO7 .styles_icon__26B2P {
          color: rgba(255, 255, 255, 0.54); }
  .styles_notificationList__3cVW6 .styles_seen__3taZ5 {
    background-color: white; }
    .styles_notificationList__3cVW6 .styles_seen__3taZ5 .styles_title__B5Zsf {
      font-weight: 500 !important; }
  .styles_notificationList__3cVW6 .styles_spinner__1KI6o::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
    border-radius: 50%;
    border: 2px solid #2196f3;
    border-top-color: #ccebff;
    -webkit-animation: styles_spin__1WB4H 0.6s linear infinite;
            animation: styles_spin__1WB4H 0.6s linear infinite; }

.styles_iconNoti__2Iu55 {
  color: #90a0b7 !important; }

.styles_loadmore__1_ZzA {
  text-align: center;
  cursor: pointer;
  color: #1262a5;
  padding: 10px;
  text-decoration: underline; }

.styles_loadingLoadmore__38fRm {
  text-align: center;
  padding: 10px 0; }

.styles_highlight__1KZ9Z {
  background-color: #E7E8F0 !important;
  border-left: 4px solid #A53B3D !important; }

.styles_nested-child__3hBZS {
  padding: 20px !important; }

.styles_animate-flicker__wSW1h {
  -webkit-animation: styles_flickerAnimation__CBYIh 2s infinite;
  animation: styles_flickerAnimation__CBYIh 2s infinite; }

.styles_item-hover-tooltip__MvZVD {
  display: flex;
  justify-content: flex-start; }

.styles_footer__3AQE6 {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px 15px 5px 70px;
  color: #444;
  z-index: 100;
  background-color: #fff;
  font-size: 13px;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  .styles_footer__3AQE6 p {
    margin: 0;
    font-size: 11px;
    line-height: 17px;
    color: #868e96; }

.styles_logo-footer__3nHNw {
  margin-right: 10px; }

.styles_safe__jWjoW {
  font-size: 11px;
  color: #FF0000; }

.styles_space__2fd7J {
  font-size: 11px;
  color: #08477C; }

.styles_title-footer__2kW6V {
  font-size: 11px;
  color: #08477C; }

.styles_img-logo__1iltJ {
  height: 13px;
  position: relative;
  top: 1px; }

.styles_hotline__POcNA {
  display: inline-block; }
  .styles_hotline__POcNA span {
    font-size: 11px; }
  .styles_hotline__POcNA .styles_icon-footer__16k2G {
    font-size: 13px;
    position: relative;
    top: 3px; }

.styles_loading-container__17Fug {
  z-index: 9999;
  width: 100%;
  position: absolute;
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_textField__fe6Xn {
  margin-left: 8px !important;
  margin-right: 8px !important;
  width: 75% !important; }

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; }

* {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: #2196f3; }
  a:hover {
    text-decoration: none; }


@import 'src/styles/modules/variables';

// roboto font
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500');
// dosis font
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500');
// vncss icons
@import url('https://static.veritone.com/veritone-ui/veritone-icons-26/style.css');
// vncss engine icons
@import url('https://static.veritone.com/engines/assets/v001/style.css');

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// workaround https://github.com/callemall/material-ui/issues/283
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $blue-1;

  &:hover {
    text-decoration: none;
  }
}

.switcher {
  position: relative;
  margin: 0 10px;
  height: calc(100vh - 20px);
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.switcher > div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background: #23282a;
  background: linear-gradient(0deg, #23282a, #23282a, rgb(70, 43, 48));
  border: 1px solid rgba(40, 40, 50, 1);
  box-sizing: border-box;
  color: white;
}

.spinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.spinner > div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background: #141414;
  border: 1px solid rgb(30, 30, 30);
  box-sizing: border-box;

  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #373737;
  background: -webkit-linear-gradient(
    left,
    #373737 10%,
    rgba(55, 55, 55, 0) 42%
  );
  background: linear-gradient(
    to right,
    rgb(220, 44, 48) 10%,
    rgba(55, 55, 55, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner > div:before {
  width: 50%;
  height: 50%;
  background: rgb(220, 44, 48);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.spinner > div:after {
  background: rgb(49, 71, 79);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* NOT FOUND */

.notFound {
  background: #141414;
  border: 1px solid rgb(30, 30, 30);
  box-sizing: border-box;
  padding: 10px;
  color: red !important;
  font-size: 32px;
  text-shadow: 1px 1px 1px black;
  display: flex;
  align-items: center;
  justify-content: center;
}

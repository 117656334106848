.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px 15px 5px 70px;
  color: #444;
  z-index: 100;
  background-color: #fff;
  font-size: 13px;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  p {
    margin: 0;
    font-size: 11px;
    line-height: 17px;
    color: #868e96;
  }
}

.logo-footer {
  margin-right: 10px;
}
.safe {
  font-size: 11px;
  color: #FF0000;
}
.space {
  font-size: 11px;
  color: #08477C;
}
.title-footer {
  font-size: 11px;
  color: #08477C;
}
.img-logo {
  height: 13px;
  position: relative;
  top: 1px;
}
.hotline{
  display: inline-block;
  span{
    font-size: 11px;
  }
  .icon-footer{
    font-size: 13px;
    position: relative;
    top: 3px;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.notification {
  display: 'flex';
  align-items: 'center'; }
  .notification .toolTipWrapper {
    display: inline-block; }
  .notification .badge {
    top: -3px;
    right: -3px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    background-color: #8f0a0c; }

.newText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* text-align: center; */
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #acc3d7;
  padding: 5px 15px;
  background: white; }

.notificationWindow {
  width: 516px;
  height: 100%;
  color: white;
  background-color: rgba(255, 145, 145, 0.25); }
  .notificationWindow .header {
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    padding: 0 16px;
    background-color: #ffffff;
    color: #03294a;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #e6e9ed; }
    .notificationWindow .header .label {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px; }
    .notificationWindow .header .read {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 10px;
      cursor: pointer; }
    .notificationWindow .header .chip {
      height: 16px;
      font-size: 10px;
      line-height: 15px;
      padding: 1px 10px 0;
      border-radius: 8px;
      margin-top: 16px;
      margin-left: 20px;
      background-color: #8f0a0c;
      color: #ffffff; }
    .notificationWindow .header .controls {
      margin-left: auto; }
    .notificationWindow .header .headerLeft {
      display: flex; }
  .notificationWindow .body {
    height: auto;
    max-height: 352px; }

.notificationList {
  overflow: auto;
  max-height: inherit;
  background-color: #ffffff; }
  .notificationList .entry {
    width: 100%;
    padding: 10px 30px 0 20px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    background-color: rgba(255, 145, 145, 0.25);
    cursor: pointer; }
    .notificationList .entry:hover {
      background-color: rgba(255, 145, 145, 0.25); }
    .notificationList .entry.fadeIn {
      -webkit-animation: fade-in 0.5s;
              animation: fade-in 0.5s; }
    .notificationList .entry.slideOut {
      opacity: 0;
      height: 0;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: -600px;
      transition: opacity 0.6s, margin-left 0.6s, height 0.4s 0.6s, padding 0.4s 0.6s, margin-top 0.4s 0.6s, margin-bottom 0.4s 0.6s; }
    .notificationList .entry.fadeOut {
      opacity: 0;
      height: 0;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      transition: opacity 0.6s, margin-left 0.6s, height 0.4s 0.6s, padding 0.4s 0.6s, margin-top 0.4s 0.6s, margin-bottom 0.4s 0.6s; }
    .notificationList .entry.noOutro {
      display: none; }
    .notificationList .entry > div:not(:last-child) {
      margin-right: 16px; }
    .notificationList .entry .visualStatus {
      width: 30px;
      padding: 0 25px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center; }
    .notificationList .entry .description {
      flex: 1 1;
      min-width: 0;
      color: #03294a; }
      .notificationList .entry .description .title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */
        align-items: center;
        text-align: justify; }
      .notificationList .entry .description .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        /* Gray 3 */
        color: #828282;
        padding: 10px 0; }
    .notificationList .entry .extra {
      width: 130px;
      font-size: 12px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.54);
      display: flex;
      flex-direction: row;
      justify-items: center; }
      .notificationList .entry .extra .description {
        flex: 1 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .notificationList .entry .extra .actions .iconButton {
        width: 32px;
        height: 32px; }
        .notificationList .entry .extra .actions .iconButton .icon {
          color: rgba(255, 255, 255, 0.54); }
  .notificationList .seen {
    background-color: white; }
    .notificationList .seen .title {
      font-weight: 500 !important; }
  .notificationList .spinner::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
    border-radius: 50%;
    border: 2px solid #2196f3;
    border-top-color: #ccebff;
    -webkit-animation: spin 0.6s linear infinite;
            animation: spin 0.6s linear infinite; }

.iconNoti {
  color: #90a0b7 !important; }

.loadmore {
  text-align: center;
  cursor: pointer;
  color: #1262a5;
  padding: 10px;
  text-decoration: underline; }

.loadingLoadmore {
  text-align: center;
  padding: 10px 0; }
